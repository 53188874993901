export class DeliveryCondition {
  constructor(deliveryConditionData) {
    this.deliveryCost = deliveryConditionData ? deliveryConditionData.delivery_cost : 0;
    this.orderMinCost = deliveryConditionData ? deliveryConditionData.order_min_cost : 0;
  }

  get deserialized() {
    return {
      delivery_cost: this.deliveryCost,
      order_min_cost: this.orderMinCost,
    };
  }
}

export const serializeDeliveryCondition = (condition) => new DeliveryCondition(condition);
// eslint-disable-next-line max-len
export const serializeDeliveryConditionList = (conditions) => conditions.map(serializeDeliveryCondition);
