export class CollectionScheduleTime {
  constructor(scheduleTime = {}) {
    this.isodows = scheduleTime.isodows || [];
    this.startTime = scheduleTime.start_time || '';
    this.endTime = scheduleTime.end_time || '';
  }

  get deserialized() {
    return {
      isodows: this.isodows,
      start_time: this.startTime,
      end_time: this.endTime,
    };
  }
}

export const serializeCollectionScheduleTime = (time) => new CollectionScheduleTime(time);

export const serializeCollectionScheduleTimeList = (times) => times
  .map(serializeCollectionScheduleTime);

export const deserializeCollectionScheduleTime = (toTime = {}) => {
  const time = new CollectionScheduleTime();
  Object.assign(time, toTime);
  return time.deserialized;
};

export const deserializeCollectionScheduleTimeList = (times = []) => times
  .map(deserializeCollectionScheduleTime);
