export class Ingredient {
  constructor(ingredient) {
    this.pk = ingredient ? ingredient.pk : null;
    this.title = ingredient ? ingredient.title : null;
  }

  get deserialized() {
    return {
      title: this.title,
    };
  }
}

export const serializeIngredient = (ingredient) => new Ingredient(ingredient);

export const serializeIngredientList = (ingredients) => ingredients.map(serializeIngredient);
