class RestaurantCommission {
  constructor(data = {}) {
    this.id = data.id;
    this.dateStart = data.commission_date_start;
    this.percent = data.commission_percent ? data.commission_percent.toString().replace('.', ',') : null;
    this.restaurantManager = data.restaurant_manager;
    this.restaurantTitle = data.restaurant_title;
    this.restaurantId = data.restaurant_id;
  }

  get deserialized() {
    return {
      commission_date_start: this.dateStart,
      commission_percent: this.percent && this.percent.length ? parseFloat(this.percent.replace(/,/, '.')) : null,
      restaurant_manager: this.restaurantManager,
      restaurant_title: this.restaurantTitle,
      restaurant_id: this.restaurantId,
    };
  }
}

export const serializeRestaurantCommission = (commission) => new RestaurantCommission(commission);

export const serializeRestaurantCommissionList = (commissions) => commissions
  .map(serializeRestaurantCommission);

export const deserializeRestaurantCommission = (toCommission) => {
  const commission = new RestaurantCommission();
  Object.assign(commission, toCommission);
  return commission.deserialized;
};

export const deserializeRestaurantCommissionList = (commissions) => commissions
  .map(deserializeRestaurantCommission);
