export class FoodTypeOrModifierOption {
  constructor(data = {}) {
    this.id = data.food_id || undefined;
    this.title = data.title || undefined;
    this.price = parseInt(data.price, 10) || 0;
    this.state = data.state || undefined;
    this.position = data.position || undefined;
    this.isVisible = data.is_visible;
  }
}

export const serializeFoodTypeOrModifierOption = (option) => new FoodTypeOrModifierOption(option);

export const serializeFoodTypeOrModifierOptionList = (options) => options
  .map(serializeFoodTypeOrModifierOption);
