const getPrepaymentType = (weekly, halfMonth) => {
  if (weekly) {
    return 'weekly';
  }
  if (halfMonth) {
    return 'halfMonth';
  }
  return 'none';
};

export class ContractPrepayment {
  constructor(data = {}) {
    this.id = data.id || '';
    this.weeklyPrepayment = data.weekly_prepayment || false;
    this.halfMonthPrepayment = data.half_month_prepayment || false;
    this.startedAt = data.started_at || '';
    this.contractId = data.contract_id || '';
    this.username = data.username || '';
    this.type = getPrepaymentType(data.weekly_prepayment, data.half_month_prepayment);
    this.isEditable = new Date().getTime() < new Date(data.started_at).getTime();
  }

  get deserialized() {
    return {
      weekly_prepayment: this.type === 'weekly',
      half_month_prepayment: this.type === 'halfMonth',
      started_at: this.startedAt,
      contract_id: this.contractId,
    };
  }
}

export const serializeContractPrepayment = (prepayment = {}) => new ContractPrepayment(prepayment);

export const deserializeContractPrepayment = (toPrepayment) => {
  const prepayment = new ContractPrepayment();
  Object.assign(prepayment, toPrepayment);
  return prepayment.deserialized;
};

export const serializeContractPrepaymentList = (list) => list.map(serializeContractPrepayment);
