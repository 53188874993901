export const measureTypes = {
  G: 'гр.',
  ML: 'мл.',
  L: 'л.',
  CM: 'см.',
  PCS: 'шт.',
};

export class Measure {
  constructor(data) {
    this.measureType = data ? data.measure_type : null;
    this.amount = data ? data.amount : 0;
  }
}

export const serializeMeasure = (measure) => new Measure(measure);
