export const url = {
  alias: '/aliases/',
  banners: 'banners/',
  cartevent: '/cartevent/',
  carteventConditions: '/cartevent-with-conditions/',
  categories: 'categories/',
  chips: 'chips',
  cities: 'cities/',
  collection: '/collection/',
  contracts: '/contracts/',
  contractsRestaurants: '/contracts_restaurants/',
  conditionCosts: 'condition_costs/',
  deliveryAreas: 'delivery_areas/',
  deliveryAreasTariffs: 'delivery_areas/tariffs',
  deliveryHotTariffs: 'hot-tariffs/',
  deliveryTariffs: 'delivery_tariffs/',
  deliveryTimes: 'delivery_times/',
  extendedRestaurant: 'extended_restaurants/',
  extendedRestaurantV2: 'v2/extended_restaurants/',
  hubs: 'hubs/',
  images: 'images/',
  incomePartners: 'v1/income_partners/',
  ingredients: 'ingredients/',
  languages: '/languages/',
  loadCsv: '/delivery_areas/uploadcsv/',
  moderationDocument: 'v3/moderation/',
  moderationRestaurant: 'v3/moderation/restaurant/',
  moderationRestaurantsList: 'v3/moderation/restaurants/',
  partners: '/partners',
  promotions: 'promotions/',
  promotions_v2: 'v2/promotions/',
  restaurant_ivr_phones: 'ivr_phones/',
  restaurant_phones: 'restaurant_phones/',
  restaurants: 'restaurants/',
  restaurantsChoices: 'restaurants/choices/',
  serviceNotifications: 'v1/service_notifications/',
  slide: '/slide/',
  story: '/story/',
  suggestions: '/suggestions/',
  topRestaurants: 'top_restaurants/',
  topRestaurantsV2: 'v2/top_restaurants/',
  users: '/users/',
  targets: '/targets/',
  srIntegrations: '/sr/integrations/',
};
