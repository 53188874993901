class BannerAction {
  constructor(action = {}) {
    this.oid = action.oid || undefined;
    this.title = action.title || '';
    this.type = action.type || 'open_web_view';
    this.url = action.url || '';
  }

  get deserialized() {
    return {
      oid: this.oid,
      title: this.type && this.type === 'open_web_view' ? this.title : undefined,
      type: this.type,
      url: this.url,
    };
  }
}

export const serializeBannerAction = (action) => new BannerAction(action);

export const deserializeBannerAction = (sourceAction) => {
  const targetAction = new BannerAction();
  Object.assign(targetAction, sourceAction);
  return targetAction.deserialized;
};
