import Vue from 'vue';
// import VueKeyCloak from '@dsb-norge/vue-keycloak-js';
import VueKeyCloak from './custom-keycloak'; // TODO: CHANGE ME AS SOON AS POSIBLE

const configOptions = {
  url: process.env.VUE_APP_KEYCLOAK_URL,
  realm: 'hermes',
  clientId: 'application',
  credentials: {
    secret: process.env.VUE_APP_KEYCLOAK_SECRET,
  },
};

Vue.use(VueKeyCloak, {
  config: configOptions,
  onReady: () => {
    // eslint-disable-next-line no-undef
    store.dispatch('authServiceReady');
  },
});
