import Vue from 'vue';

export const events = {
  categoryState: 'category:state',
  categorySelected: 'category:selected',
  categoryList: 'category:list',
  deliveryTariffCreated: 'delivery_tariff:created',
  deliveryTariffUpdated: 'delivery_tariff:updated',
  deliveryTariffDeleted: 'delivery_tariff:deleted',
  promotionCreated: 'promotion:created',
  promotionUpdated: 'promotion:updated',
  deletionPopUpDisable: 'extended_restaurant:deletionPopUpDisable',
  foodSelection: 'extended_restaurant:foodSelection',
  additionCreated: 'extended_restaurant:addition-created',
  additionChanged: 'extended_restaurant:addition-changed',
  additionDeleted: 'extended_restaurant:addition-deleted',
  modificationCopied: 'extended_restaurant:modification-copied',
  ingredientCreated: 'extended_restaurant:ingredient-created',
  ingredientDeleted: 'extended_restaurant:ingredient-deleted',
  topCreated: 'top_6:new-top-created',
};

export const eventHub = new Vue();
