class CollectionZone {
  constructor(zone = {}) {
    this.cities = zone.cities || [];
  }

  get deserialized() {
    return {
      city_ids: this.cities.length ? this.cities.map((city) => city.id) : [],
      restaurant_ids: [],
    };
  }
}

export const serializeCollectionZone = (zone = {}) => new CollectionZone(zone);

export const deserializeCollectionZone = (toZone = {}) => {
  const zone = new CollectionZone();
  Object.assign(zone, toZone);
  return zone.deserialized;
};
