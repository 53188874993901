import {
  serializeFoodModifierOptionList, deserializeFoodModifierOptionList,
} from './foodModifierOptionSerializer';

const choicesByRequired = {
  true: 1,
  false: 0,
};

const deseriliazedOptions = (options, maxChoices, required) => {
  options.forEach((option) => {
    // eslint-disable-next-line no-param-reassign
    option.maxChoices = maxChoices;
    // eslint-disable-next-line no-param-reassign
    option.minChoices = maxChoices > 0 && required ? 0 : option.minChoices;
  });
  return deserializeFoodModifierOptionList(options);
};

export class FoodModifier {
  constructor(data = {}) {
    this.id = data.category_id || undefined;
    this.state = data.category_state || undefined;
    this.title = data.category_title || undefined;
    this.foodModifierId = data.food_modifier_id || undefined;
    this.isRequired = data.is_required;
    this.maxChoices = data.max_choices || choicesByRequired[data.is_required];
    this.minChoices = data.min_choices || choicesByRequired[data.is_required];
    this.options = data && data.options ? serializeFoodModifierOptionList(data.options) : [];
    this.selectedAllOptions = false;
    this.position = data.position || 0;
  }

  get deserialized() {
    return {
      category_id: this.id,
      category_state: this.state,
      category_title: this.title,
      is_required: this.isRequired,
      min_choices: this.minChoices > this.maxChoices ? this.maxChoices : this.minChoices,
      max_choices: parseInt(this.maxChoices, 10),
      options: this.options
        ? deseriliazedOptions(this.options, this.minChoices > this.maxChoices
          ? this.maxChoices : this.minChoices, this.isRequired) : [],
      position: this.position,
    };
  }
}

export const serializeFoodModifier = (modifier) => new FoodModifier(modifier);

export const serializeFoodModifierList = (items = []) => items.map(serializeFoodModifier);

export const deserializeFoodModifier = (toFoodModifier) => {
  const foodModifier = new FoodModifier();
  Object.assign(foodModifier, toFoodModifier);
  return foodModifier.deserialized;
};

export const deserializeFoodModifierList = (items = []) => items.map(deserializeFoodModifier);
