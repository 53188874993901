import { serializeCollectionPresetParamsList } from '@/serializers/collectionPresetParamSerializer';

class CollectionPreset {
  constructor(preset = {}) {
    this.id = preset.id || null;
    this.title = preset.title || '';
    this.typeOf = preset.type_of || '';
    this.description = preset.description || '';
    this.params = preset.params ? serializeCollectionPresetParamsList(preset.params) : [];
  }
}

export const serializeCollectionPreset = (preset) => new CollectionPreset(preset);

export const serializeCollectionPresetList = (presets) => presets.map(serializeCollectionPreset);
