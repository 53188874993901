import { serializeRestaurantSchedule } from '@/serializers/restaurantScheduleSerializer';

export class HubRestaurant {
  constructor(data) {
    this.id = data.restaurant_id || 0;
    this.city = data.city || '';
    this.title = data.title || '';
    this.state = data.state === 'active';
    this.deliveryTitle = data.will_be_delivered_by || '';
    this.paymentMethods = data.payment_methods || '';
    this.isInHub = data.is_in_hub;
    this.schedule = serializeRestaurantSchedule(data.schedule) || [];
  }

  get deserialized() {
    return {
      id: this.id || undefined,
      city: this.city || '',
      title: this.title || '',
      state: this.state,
      deliveryTitle: this.deliveryTitle || '',
      paymentMethods: this.paymentMethods || '',
      isInHub: this.isInHub,
      schedule: this.schedule,
    };
  }
}

export const serializeHubRestaurant = (restaurant) => new HubRestaurant(restaurant);
export const serializeHubRestaurantsList = (restaurants) => restaurants.map(serializeHubRestaurant);
