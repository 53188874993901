import { serializeCoordinate } from '@/serializers/coordinateSerializer';

export class Location {
  constructor(coordinateData) {
    this.coordinate = coordinateData
      ? serializeCoordinate(coordinateData.coordinate)
      : serializeCoordinate({ latitude: 0, longitude: 0 });
    this.text = coordinateData ? coordinateData.text : undefined;
  }

  get location() {
    return [this.coordinate.latitude, this.coordinate.longitude, this.text];
  }
}

export const serializeLocation = (location = {}) => new Location(location);
export const serializeLocationList = (locations = []) => locations.map(serializeLocation);
