import { serializeIngredientList } from './ingredientSerializer';
import { serializeMeasure } from './measureSerializer';
import { serializeAdditionList } from './additionSerializer';
import { serializeFoodModificationList } from './foodModificationSerializer';

import { deserializeIngredientList } from './ingredientDeserializer';
import { deserializeMeasure } from './measureDeserializer';
import { deserializeAdditionList } from './additionDeserializer';
import { deserializeFoodModificationList } from './foodModificationDeserializer';

export class Food {
  constructor(data) {
    this.oid = data ? data.oid : undefined;
    this.title = data ? data.title : undefined;
    this.sellingText = data ? data.selling_text : undefined;
    this.state = data ? data.state : undefined;
    this.price = data ? data.price : undefined;
    this.logo = data ? data.logo : undefined;
    this.cookingMethod = data ? data.cooking_method : undefined;
    this.measure = data && data.measure ? serializeMeasure(data.measure) : undefined;
    this.ingredients = data ? serializeIngredientList(data.ingredients) : [];
    this.additions = data ? serializeAdditionList(data.additions) : [];
    this.modifications = data ? serializeFoodModificationList(data.modifications) : [];
    this.position = data ? data.position : undefined;
  }

  get deserialized() {
    return {
      oid: this.oid,
      title: this.title,
      selling_text: this.sellingText,
      state: this.state,
      price: typeof this.price === 'string' || typeof this.price === 'number' ? parseInt(this.price, 10) : undefined,
      logo: this.logo,
      cooking_method: this.cookingMethod,
      measure: this.measure ? deserializeMeasure(this.measure) : undefined,
      ingredients: this.ingredients ? deserializeIngredientList(this.ingredients) : [],
      additions: this.additions ? deserializeAdditionList(this.additions) : [],
      modifications: this.modifications ? deserializeFoodModificationList(this.modifications) : [],
      position: this.position,
    };
  }
}

export const serializeFood = (food) => new Food(food);

export const serializeFoodList = (foods) => foods.map(serializeFood);
