const dateToNumber = (stringDate = '2022-02-03') => Math.abs(stringDate.match(/\d+/g).join(''));

class Commission {
  constructor(commission = {}) {
    this.id = commission.id || null;
    this.percent = commission.percent || null;
    this.dateStart = commission.data_start || '';
    this.restaurantId = commission.restaurant_id || null;
    this.isDeleted = commission.is_deleted || false;
  }

  get deserialized() {
    return {
      percent: this.percent.replace(/,/, '.'),
      date_start: this.dateStart,
      restaurant_id: this.restaurantId,
      is_deleted: this.isDeleted,
    };
  }
}

export const serializeCommission = (commission) => new Commission(commission);

export const serializeCommissionsList = (commissions) => commissions
  .map(serializeCommission)
  .sort((a, b) => (dateToNumber(a.date) < dateToNumber(b.date) ? -1 : 1));

export const deserializeCommission = (commission = {}) => {
  const instance = new Commission(commission);
  Object.assign(instance, commission);
  return instance.deserialized;
};
export const deserializeCommissionsList = (commissions) => commissions.map(deserializeCommission);
