export class Slide {
  constructor(slide = {}) {
    this.id = slide.id;
    this.storyId = slide.story_id;
    this.image = slide.image;
    this.position = slide.slide_position || 0;
    this.buttonName = slide.button_name;
    this.buttonType = slide.button_type;
    this.buttonLink = slide.button_link;
    this.buttonColor = slide.button_color;
    this.textColor = slide.text_color;
  }

  get deserialized() {
    return {
      id: this.id,
      story_id: this.storyId,
      image: this.image,
      slide_position: this.position,
      button_display: !!this.buttonName?.length,
      button_name: this.buttonName,
      button_type: this.buttonType,
      button_link: this.buttonLink,
      button_color: this.buttonColor,
      text_color: this.textColor,
    };
  }
}

export const serializeSlide = (slide) => new Slide(slide);

export const serializeSlidesList = (slides) => slides.map(serializeSlide);

export const deserializeSlide = (toSlide) => {
  const slide = new Slide();
  Object.assign(slide, toSlide);
  return slide.deserialized;
};

export const deserializeSlidesList = (slides) => slides.map(deserializeSlide);
