import { serializeLocation } from '@/serializers/locationSerializer';
import { serializeCategoryList } from '@/serializers/categorySerializer';
import { serializeRestaurantSchedule } from '@/serializers/restaurantScheduleSerializer';
import { serializeRestaurantPhonesList, deserializeRestaurantPhonesList } from '@/serializers/restaurantPhoneSerializer';

export const paymentTypeTitles = {
  cash: 'Наличные',
  rakhmet: 'Рахмет',
  bonus: 'Бонусы',
  card: 'Карты',
};

export const paymentTypes = {
  cash: false,
  rakhmet: false,
  bonus: false,
  card: false,
};

export const stateTitles = {
  filling_content: 'Заполняется контент',
  on_automation: 'На автоматизации',
  active: 'Активный',
  closed_on_schedule: 'Закрыт по расписанию',
  closed_for_price_change: 'Закрыт на корректировку меню',
  closed_for_repairs: 'Закрыт на ремонт',
  does_not_respond: 'Не отвечают',
  closed_by_themselves: 'Поставили себя на стоп',
  delivery_does_not_work: 'Доставка не работает',
  not_working_with_us: 'Не сотрудничают с нами',
  removed: 'Удален',
};

export class Restaurant {
  constructor(restaurant = {}) {
    this.pk = restaurant.pk || null;
    this.title = restaurant.title || null;
    this.categories = restaurant ? serializeCategoryList(restaurant.categories) : [];
    this.hubs = restaurant.hubs || [];
    this.logo = restaurant.logo || null;
    this.image = restaurant.image || null;
    this.deliveryService = restaurant.will_be_delivered_by || null;
    this.state = restaurant.state || null;
    this.location = restaurant ? serializeLocation(restaurant.location) : null;
    this.paymentTypes = restaurant.payment_methods || paymentTypes;
    this.synonyms = restaurant.synonyms || [];
    this.schedule = restaurant ? serializeRestaurantSchedule(restaurant.schedule) : [];
    this.managerEmail = restaurant.manager_email || '';
    this.phones = restaurant && restaurant.phones
      ? serializeRestaurantPhonesList(restaurant.phones) : [];
    this.timeOD = restaurant.od_default_time || 15;
    this.timeVD = restaurant.vd_default_time || 30;
    this.notificationType = restaurant.notification_type || '';
    this.saveOldMenu = restaurant.save_old_menu;
    this.isCommentsAllowed = restaurant.is_comments_allowed;
    this.totalCount = restaurant.total_count || 0;
    this.city = {
      title: restaurant.city?.title || '',
    };
  }

  get stateTitle() {
    return stateTitles[this.state];
  }

  get activity() {
    const statesInProcess = ['filling_content', 'on_automation'];
    if (this.state === 'active') {
      return 'active';
    } if (statesInProcess.includes(this.state)) {
      return 'inProcess';
    }
    return 'inactive';
  }

  get deserialized() {
    return {
      title: this.title,
      categories: this.categories.map((cat) => cat.id),
      hubs: this.hubs,
      logo: this.logo,
      image: this.image,
      will_be_delivered_by: this.deliveryService,
      state: this.state,
      location: this.location,
      payment_methods: this.paymentTypes,
      schedule: this.schedule.map((sch) => sch.deserialized),
      synonyms: this.synonyms,
      manager_email: this.managerEmail,
      phones: deserializeRestaurantPhonesList(this.phones),
      od_default_time: this.timeOD,
      vd_default_time: this.timeVD,
      notification_type: this.notificationType,
      save_old_menu: this.saveOldMenu,
      is_comments_allowed: this.isCommentsAllowed,
    };
  }
}

export const serializeRestaurant = (restaurant) => new Restaurant(restaurant);

export const serializeRestaurantList = (restaurants) => restaurants.map(serializeRestaurant);
