class SynonymsRequest {
  constructor(data = {}) {
    this.id = data.id || null;
    this.alias = data.alias || '';
    this.type = data.type || 'restaurant';
    this.synonyms = data.synonyms || [];
  }

  get deserialized() {
    return {
      alias: this.alias,
      type: this.type,
      synonyms: this.synonyms,
    };
  }
}

export const serializeSynonymsRequest = (data) => new SynonymsRequest(data);
export const serializeSynonymsRequestList = (list) => list.map(serializeSynonymsRequest);

export const deserializeSynonymsRequest = (toSynonyms) => {
  const synonyms = new SynonymsRequest();
  Object.assign(synonyms, toSynonyms);
  return synonyms.deserialized;
};
