export class FoodOrOptionCategory {
  constructor(data) {
    this.id = data ? data.category_id : undefined;
    this.title = data ? data.category_title : undefined;
    this.type = data ? data.type : undefined;
  }

  get deserialized() {
    return {
      category_id: this.id,
      category_title: this.title,
    };
  }
}

export const serializeFoodOrOptionCategory = (val) => new FoodOrOptionCategory(val);

export const serializeFoodOrOptionCategoryList = (list) => list
  .map(serializeFoodOrOptionCategory);

export const deserializeFoodOrOptionCategory = (toFoodOrOptionCategory) => {
  const foodOrOptionCategory = new FoodOrOptionCategory();
  Object.assign(foodOrOptionCategory, toFoodOrOptionCategory);
  return foodOrOptionCategory.deserialized;
};

export const deserializeFoodOrOptionCategoryList = (list) => list
  .map(deserializeFoodOrOptionCategory);
