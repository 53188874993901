import {
  SET_AUTH_SERVICE_READY,
  SET_CITIES,
  SET_CITIES_SHORT,
  SET_DELIVERY_TARIFFS,
  SET_MENU_OPTIONS,
  SET_ALL_FOODS_AND_OPTIONS,
  SET_DELIVERY_TIMES,
} from './mutation-types';
/* eslint-disable no-param-reassign */

export default {
  [SET_AUTH_SERVICE_READY](state, status) {
    state.authServiceIsReady = status;
  },
  [SET_CITIES](state, cities) {
    state.cities = cities;
  },
  [SET_CITIES_SHORT](state, citiesShort) {
    state.citiesShort = citiesShort;
  },
  [SET_DELIVERY_TARIFFS](state, deliveryTariffs) {
    state.deliveryTariffs = deliveryTariffs;
  },
  [SET_MENU_OPTIONS](state, menuOptions) {
    state.menuOptions = menuOptions;
  },
  [SET_ALL_FOODS_AND_OPTIONS](state, allFoodsAndOptions) {
    state.allFoodsAndOptions = allFoodsAndOptions;
  },
  [SET_DELIVERY_TIMES](state, deliveryTimes) {
    state.deliveryTimes = deliveryTimes;
  },
};
