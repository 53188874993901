const statesToRename = {
  true: 'active',
  false: 'inactive',
};

export class Notification {
  constructor(notification = {}) {
    this.id = notification.id || '';
    this.title = notification.ru_title || '';
    this.kzTitle = notification.kz_title || '';
    this.ruTitle = notification.ru_title || '';
    this.kzDescription = notification.kz_description || '';
    this.ruDescription = notification.ru_description || '';
    this.backgroundImage = notification.background_image || '';
    this.backgroundColor = notification.background_color || '';
    this.icon = notification.icon || '';
    this.arrangement = notification.arrangement || 0;
    this.dtStarted = notification.dt_started || '';
    this.dtEnded = notification.dt_ended || '';
    this.dtCreated = notification.dt_create || '';
    this.dtUpdated = notification.dt_updated || '';
    this.isActive = notification.is_active || false;
    this.state = statesToRename[notification.is_active || false];
    this.targets = notification.targets || [];
    this.targetsToString = notification.targets ? notification.targets.map((target) => target.title).join(', ') : '';
    this.cities = notification.cities || [];
    this.hubs = notification.hubs || [];
  }

  get deserialized() {
    return {
      id: this.id,
      kz_title: this.kzTitle,
      ru_title: this.ruTitle,
      kz_description: this.kzDescription,
      ru_description: this.ruDescription,
      background_image: this.backgroundImage,
      background_color: this.backgroundColor,
      icon: this.icon,
      arrangement: this.arrangement,
      dt_started: this.dtStarted,
      dt_ended: this.dtEnded,
      is_active: this.state === 'active',
      targets: this.targets ? this.targets.map((target) => Number(target.pk || target.id)) : [],
      cities: this.cities ? this.cities.map((city) => Number(city.pk || city.id)) : [],
      hubs: this.hubs ? this.hubs.map((hub) => hub.id) : [],
    };
  }
}

export const serializeNotification = (notification) => new Notification(notification);

export const serializeNotificationsList = (list) => list.map(serializeNotification);

export const deserializeNotification = (toNotification) => {
  const notification = new Notification();
  Object.assign(notification, toNotification);
  return notification.deserialized;
};

export const deserializeNotificationsList = (list) => list.map(deserializeNotification);
