class Addition {
  constructor(data) {
    this.oid = data.oid;
    this.title = data.title;
    this.price = data.price;
  }
}
export const deserializeAddition = (addition) => new Addition(addition);

export const deserializeAdditionList = (additions) => additions.map(deserializeAddition);
