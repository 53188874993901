export class StoryPersonalizedSerializer {
  constructor(data = {}) {
    this.hasUsers = data.has_users;
    this.maxOrdersCount = data.max_orders_count;
    this.minOrdersCount = data.min_orders_count;
    this.showForUnauthorized = data.show_for_unauthorized;
    this.usersExcel = '';
  }
}

export const serializeStoryPersonalized = (data = {}) => new StoryPersonalizedSerializer(data);
