export const itemsPerPage = 20;

export const alphabeticalSortOptions = [
  { title: 'A > Я', value: 'descending' },
  { title: 'А < Я', value: 'ascending' },
];

export const numericalSortOptions = [
  { title: 'По возрастанию', value: 'ascending' },
  { title: 'По убыванию', value: 'descending' },
];

export const typeOptions = {
  foodType: [
    { title: 'Все', value: 'all' },
    { title: 'Категория', value: 'is_category' },
    { title: 'Модификатор', value: 'is_modifier' },
  ],
  food: [
    { title: 'Все', value: 'all' },
    { title: 'Блюдо', value: 'is_food' },
    { title: 'Опция модификатора', value: 'is_option' },
  ],
};

export const objectsAreSame = (x, y) => {
  // eslint-disable-next-line no-shadow
  let objectsAreSame = true;
  // eslint-disable-next-line no-restricted-syntax
  for (const propertyName in x) {
    if (x[propertyName] !== y[propertyName]) {
      objectsAreSame = false;
      break;
    }
  }
  return objectsAreSame;
};

export const filterStates = [{ title: 'Активно', value: 'active' }, { title: 'Отключено', value: 'inactive' }];

export const targetTitles = {
  android: 'Android',
  ios: 'iOS',
  web: 'Web',
  'mobile-web': 'Mobile Web',
  choco: 'Choco',
};

export const targets = Object.keys(targetTitles)
  .map((key) => ({ title: targetTitles[key], value: key }));

export const languageTitles = {
  kz: 'Қазақша',
  ru: 'Русский',
};
