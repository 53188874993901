import { mask } from 'vue-the-mask';
import Vue from 'vue';

const applyMask = {
  bind(el, binding) {
    if (binding.value && binding.value !== '') {
      mask(el, binding);
    }
  },
  unbind() {},
};

Vue.directive('mask', applyMask);
