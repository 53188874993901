export class ModificationChoice {
  constructor(data) {
    this.oid = data ? data.oid : undefined;
    this.title = data ? data.title : undefined;
    this.price = data ? data.price || 0 : undefined;
    this.maxOfChoices = data ? data.max_of_choices : undefined;
    this.minOfChoices = data ? data.min_of_choices : undefined;
    this.state = data ? data.state : undefined;
    this.externalServiceId = data ? data.external_service_id : undefined;
  }

  get deserialized() {
    return {
      oid: this.oid,
      title: this.title,
      max_of_choices: this.maxOfChoices ? parseInt(this.maxOfChoices, 10) : 0,
      min_of_choices: this.minOfChoices ? parseInt(this.minOfChoices, 10) : 0,
      price: this.price || 0,
      state: this.state || undefined,
      external_service_id: this.externalServiceId || undefined,
    };
  }
}

export const serializeModificationChoice = (choice) => new ModificationChoice(choice);

export const serializeModificationChoiceList = (chs) => chs.map(serializeModificationChoice);

export const assignToNewChoice = (choice) => {
  const newChoice = new ModificationChoice();
  Object.assign(newChoice, choice);
  return newChoice;
};

export const assignToNewChoicesList = (choices) => choices.map(assignToNewChoice);

export const deserializeModificationChoice = (choice) => {
  const modification = new ModificationChoice();
  Object.assign(modification, 0, choice);
  return modification.deserialized;
};

export const deserializeModificationChoiceList = (chs) => chs.map(deserializeModificationChoice);
