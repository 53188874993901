import { serializeFoodTypeList } from '@/serializers/foodTypeSerializer';
import { deserializeFoodTypeList } from './foodTypeDeserializer';

export class ExtendedRestaurant {
  constructor(data) {
    this.foodTypes = data ? serializeFoodTypeList(data.food_types) : null;
  }

  get deserializeFoodTypes() {
    return {
      food_types: deserializeFoodTypeList(this.foodTypes),
    };
  }
}

export const serializeExtendedRestaurant = (extRest) => new ExtendedRestaurant(extRest);
