import { targetTitles } from '@/helpers/table';
import {
  serializeCollectionZone,
  deserializeCollectionZone,
} from '@/serializers/collectionZoneSerializer';
import {
  serializeCollectionScheduleTimeList,
  deserializeCollectionScheduleTimeList,
} from '@/serializers/collectionScheduleTimeSerializer';
import {
  serializeCollectionContent,
  deserializeCollectionContent,
} from '@/serializers/collectionContentSerializer';
import {
  serializeCollectionCondition,
  deserializeCollectionCondition,
} from '@/serializers/collectionConditionSerializer';

const typeTitles = {
  restaurant: 'Рестораны',
  food: 'Блюда',
  collection: 'Мегаколлекция',
};

const formatDateEnd = (date) => (date.includes('9999') ? '' : date);

export class Collection {
  constructor(collection = {}) {
    this.id = collection.id || '';
    this.titleKz = collection.title_kz || '';
    this.titleRu = collection.title_ru || '';
    this.title = collection.title_ru || '';
    this.h1TitleKz = collection.h1_title_kz;
    this.h1TitleRu = collection.h1_title_ru;
    this.state = collection.status || '';
    this.dateStart = collection.date_start || '';
    this.dateEnd = collection.date_end ? formatDateEnd(collection.date_end) : '';
    this.cities = collection.cities || [];
    this.content = serializeCollectionContent(collection.content);
    this.contents = collection.contents || [];
    this.platform = collection.platform
      ? collection.platform.map((target) => ({ title: targetTitles[target], code: target })) : [];
    this.platformToString = collection.platform
      ? collection.platform.map((target) => targetTitles[target]).join(', ') : [];
    this.restaurants = collection.restaurants || [];
    this.typeOf = collection.type_of || '';
    this.sort = collection.sort || '';
    this.typeOfTitle = collection.type_of ? typeTitles[collection.type_of] : '';
    this.seoTitleKz = collection.seo_title_kz || '';
    this.seoTitleRu = collection.seo_title_ru || '';
    this.seoTextKz = collection.seo_text_kz || '';
    this.seoTextRu = collection.seo_text_ru || '';
    this.seoDescriptionKz = collection.seo_description_kz || '';
    this.seoDescriptionRu = collection.seo_description_ru || '';
    this.image = collection.image || '';
    this.position = collection.position || null;
    this.design = collection.design || '';
    this.slug = collection.slug || '';
    this.scheduleTimes = collection.schedule_times
      ? serializeCollectionScheduleTimeList(collection.schedule_times) : [];
    this.zone = collection.zone ? serializeCollectionZone(collection.zone) : {};
    this.condition = collection.conditions
      ? serializeCollectionCondition(collection.conditions[0]) : {};
    this.dateCreated = collection.created || '';
    this.dateUpdated = collection.updated || '';
  }

  get deserialized() {
    return {
      title_kz: this.titleKz,
      title_ru: this.titleRu,
      h1_title_kz: this.h1TitleKz || this.titleKz,
      h1_title_ru: this.h1TitleRu || this.titleRu,
      status: this.state,
      date_start: this.dateStart,
      date_end: this.dateEnd || null,
      content: deserializeCollectionContent(this.content),
      platform: this.platform ? this.platform.map((target) => target.code) : [],
      type_of: this.typeOf,
      sort: this.sort,
      seo_title_kz: this.seoTitleKz,
      seo_title_ru: this.seoTitleRu,
      seo_text_kz: this.seoTextKz,
      seo_text_ru: this.seoTextRu,
      seo_description_kz: this.seoDescriptionKz,
      seo_description_ru: this.seoDescriptionRu,
      image: this.image || null,
      position: this.position,
      design: this.design,
      slug: this.slug,
      schedule_times: deserializeCollectionScheduleTimeList(this.scheduleTimes),
      zone: deserializeCollectionZone(this.zone),
      conditions: this.condition.key ? [deserializeCollectionCondition(this.condition)] : [],
    };
  }
}

export const serializeCollection = (collection) => new Collection(collection);

export const serializeCollectionList = (collections) => collections.map(serializeCollection);

export const deserializeCollection = (toCollection) => {
  const collection = new Collection();
  Object.assign(collection, toCollection);
  return collection.deserialized;
};

export const deserializeCollectionList = (collections) => collections.map(deserializeCollection);
