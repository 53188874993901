export class Partners {
  constructor(data = {}) {
    this.login = data.api_login || null;
    this.title = data.title || null;
    this.state = data.is_active ? 'active' : 'inactive';
    this.createUrl = data.create_orders_url || '';
    this.updateUrl = data.update_orders_url || '';
    this.createdDt = data.created_dt || null;
    this.updatedDt = data.updated_dt || null;
    this.id = data.id || null;
    this.totalCount = data.total_count || 0;
  }

  get deserialized() {
    return {
      create_orders_url: this.createUrl,
      title: this.title,
      update_orders_url: this.updateUrl,
    };
  }
}

export const serializePartners = (val) => new Partners(val);

export const serializePartnersList = (list) => list.map(serializePartners);

export const deserializePartners = (toPartners) => {
  const partners = new Partners();
  Object.assign(partners, toPartners);
  return partners.deserialized;
};
