export class Coordinate {
  constructor(coordinateData) {
    this.latitude = coordinateData ? coordinateData.latitude : 0;
    this.longitude = coordinateData ? coordinateData.longitude : 0;
  }

  get coordinates() {
    return [this.latitude, this.longitude];
  }
}

export const serializeCoordinate = (coordinate) => new Coordinate(coordinate);
export const serializeCoordinateList = (coordinates) => coordinates.map(serializeCoordinate);
