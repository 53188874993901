import { serializeModerationDocumentsList } from './moderationDocumentSerializer';

class ModerationRestaurant {
  constructor(data) {
    this.title = data.restaurant_title || '';
    this.documents = data && data.documents ? serializeModerationDocumentsList(data.documents) : [];
  }
}

export const serializeModerationRestaurant = (restaurant) => new ModerationRestaurant(restaurant);
