/* eslint no-shadow: ["error", { "allow": ["Vue"] }] */
import Vue from 'vue';

const amplitude = require('amplitude-js');

const amplitudeToVue = {
  install: (Vue, { apiKey, userId, config }) => {
    amplitude.getInstance().init(apiKey, userId, config);
    // eslint-disable-next-line
    Vue.prototype.$amplitude = amplitude;
    // eslint-disable-next-line
    Vue.prototype.$amplitudeEvent = (eventName, info) => {
      amplitude.getInstance().logEvent(eventName, info);
    };
  },
};
const config = {
  apiKey: process.env.VUE_APP_AMPLITUDE_API_KEY,
};

Vue.use(amplitudeToVue, config);
