import './global/sentry';

import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store/store';
import vuetify from './plugins/vuetify';
import './plugins/amplitude';
import './registerServiceWorker';
import './plugins/keycloak';
import './plugins/v-mask';

Vue.config.productionTip = false;
new Vue({
  router,
  vuetify,
  store,
  render: (h) => h(App),
}).$mount('#app');
