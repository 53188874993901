class RestaurantIVRPhone {
  constructor(data = {}) {
    this.id = data ? data.id : null;
    this.title = data ? data.title : '';
    this.number = data ? data.phone_number : '';
    this.restaurantId = data ? data.restaurant_id : null;
  }

  get deserialized() {
    return {
      title: this.title,
      phone_number: this.number,
      restaurant_id: this.restaurantId,
    };
  }
}

export const serializeRestaurantIVRPhone = (phone) => new RestaurantIVRPhone(phone);
export const serializeRestaurantIVRPhonesList = (phones) => phones.map(serializeRestaurantIVRPhone);
