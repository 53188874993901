class ContactRole {
  constructor(role) {
    this.text = role ? role[Object.keys(role)[0]] : 'Директор';
    this.value = role ? Object.keys(role)[0] : 'director';
  }

  get deserialized() {
    return {
      [this.value]: this.text,
    };
  }
}

export const serializeContactRole = (role) => new ContactRole(role);
export const serializeContactRolesList = (roles) => roles.map(serializeContactRole);

export const deserializeContactRole = (role) => {
  const instance = new ContactRole(role);
  Object.assign(instance, role);
  return instance.deserialized;
};
export const deserializeContactRolesList = (roles) => roles.map(deserializeContactRole);
