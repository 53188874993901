import { deserializeModificationChoiceList } from './foodModificationChoiceDeserializer';

class FoodModification {
  constructor(data) {
    this.oid = data.oid;
    this.title = data.title;
    this.max_of_choices = parseInt(data.maxOfChoices, 10);
    this.min_of_choices = parseInt(data.minOfChoices, 10);
    this.modification_choices = data.modificationChoices
      ? deserializeModificationChoiceList(data.modificationChoices)
      : undefined;
  }
}

export const deserializeFoodModification = (modification) => new FoodModification(modification);

export const deserializeFoodModificationList = (mods) => mods.map(deserializeFoodModification);
