import { deserializeFoodList } from './foodDeserializer';
import { serializeFoodList } from './foodSerializer';

export class FoodType {
  constructor(data) {
    this.oid = data ? data.oid : undefined;
    this.dishesCost = data ? data.dishes_cost : undefined;
    this.title = data ? data.title : undefined;
    this.state = data ? data.state : undefined;
    this.foods = data ? serializeFoodList(data.foods) : [];
    this.position = data ? data.position : undefined;
  }

  get deserialized() {
    return {
      oid: this.oid,
      dishes_cost: this.dishesCost,
      state: this.state,
      title: this.title,
      foods: this.foods ? deserializeFoodList(this.foods) : undefined,
      position: this.position,
    };
  }
}

export const serializeFoodType = (foodType) => new FoodType(foodType);

export const serializeFoodTypeList = (foodTypes) => foodTypes.map(serializeFoodType);

export const deserializeFoodType = (toFoodType) => {
  const foodType = new FoodType();
  Object.assign(foodType, toFoodType);
  return foodType.deserialized;
};

export const deserializeFoodTypeList = (foodTypes) => foodTypes.map(deserializeFoodType);
