class ContractRestaurant {
  constructor(restaurant) {
    // eslint-disable-next-line no-underscore-dangle
    this.id = restaurant._id;
    this.title = restaurant.title || '';
    this.restaurantContractId = restaurant.restaurant_contract_id || 0;
    this.isDefault = restaurant.is_default || false;
    this.manager = restaurant.manager_email || 'без менеджера';
  }

  get deserialized() {
    return {
      _id: this.id,
      title: this.title,
      restaurant_contract_id: this.restaurantContractId,
      is_default: this.isDefault,
      manager: this.manager,
    };
  }
}

export const serializeContractRestaurant = (restaurant) => new ContractRestaurant(restaurant);
export const serializeContractsRestaurantsList = (restaurants) => restaurants
  .map(serializeContractRestaurant);

export const deserializeContractRestaurant = (restaurant) => {
  const instance = new ContractRestaurant(restaurant);
  Object.assign(instance, restaurant);
  return instance.deserialized;
};
export const deserializeContractsRestaurantsList = (restaurants) => restaurants
  .map(deserializeContractRestaurant);
