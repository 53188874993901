import { formatDate, recentlyUpdated } from '../helpers/date';
import { serializeFoodTypeOrModifierOptionList } from './foodTypeOrModifierOptionSerializer';

export class FoodTypeOrModifier {
  constructor(data) {
    this.id = data ? data.id : undefined;
    this.title = data ? data.title : '';
    this.state = data ? data.state : undefined;
    this.isCategory = data ? data.is_category : undefined;
    this.isModifier = data ? data.is_modifier : undefined;
    this.createdDt = data ? formatDate(data.created_dt) : undefined;
    this.updatedDt = data ? formatDate(data.updated_dt) : undefined;
    this.atModeration = data ? data.at_moderation : false;
    this.options = data ? serializeFoodTypeOrModifierOptionList(data.options) : [];
    this.isRecentlyUpdated = data ? recentlyUpdated(data) : false;
    this.position = data ? data.position : undefined;
    this.isVisible = data && 'is_visible' in data ? data.is_visible : true;
  }

  get deserialized() {
    return {
      id: this.id,
      title: this.title,
      state: this.state,
      is_category: this.isCategory,
      is_modifier: this.isModifier,
      options: this.options.length
        ? this.options.map((option) => ({ food_id: option.id, position: option.position }))
        : [],
      position: this.position,
      is_visible: this.isVisible,
    };
  }
}

export const serializeFoodTypeOrModifier = (val) => new FoodTypeOrModifier(val);

export const serializeFoodTypeOrModifierList = (list) => list.map(serializeFoodTypeOrModifier);

export const deserializeFoodTypeOrModifier = (toFoodTypeOrModifier) => {
  const foodTypeOrModifier = new FoodTypeOrModifier();
  Object.assign(foodTypeOrModifier, toFoodTypeOrModifier);
  return foodTypeOrModifier.deserialized;
};

export const deserializeFoodTypeOrModifierList = (list) => list.map(deserializeFoodTypeOrModifier);
