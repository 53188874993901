const statesByIsActive = {
  true: 'Активирован',
  false: 'Деактивирован',
};
const statesByType = {
  restaurant: 'На заведение',
  delivery: 'На доставку',
};
export class Chip {
  constructor(data = {}) {
    this.id = data.id;
    this.kzTitle = data.kz_title || '';
    this.ruTitle = data.ru_title || '';
    this.rating = data.rating || 0;
    this.sortPosition = data.sort_position || 0;
    this.isActive = data.is_active || false;
    this.state = statesByIsActive[data.is_active || false];
    this.targets = data.target || [];
    this.createdAt = data.created_at || '';
    this.updatedAt = data.updated_at || '';
    this.entity_type = data.entity_type;
    this.entity_type_text = statesByType[data.entity_type];
  }

  get deserialized() {
    return {
      id: this.id,
      kz_title: this.kzTitle,
      ru_title: this.ruTitle,
      rating: this.rating,
      sort_position: this.sortPosition,
      is_active: this.isActive,
      target_ids: this.targets,
      entity_type: this.entity_type,
    };
  }
}

export const serializeChip = (chip) => new Chip(chip);

export const serializeChipList = (chips = []) => chips.map(serializeChip);

export const deserializeChip = (toChip) => {
  const chip = new Chip();
  Object.assign(chip, toChip);
  return chip.deserialized;
};

export const deserializeChipList = (chips = []) => chips.map(deserializeChip);
