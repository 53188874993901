import { formatDateLong, isOlderThanToday } from '@/helpers/date';
import { suggestionPositions } from '@/conditions/positions';

export class Suggestion {
  constructor(suggestion = {}) {
    this.id = suggestion.id;
    this.title = suggestion.title;
    this.startingDate = suggestion.starting_date;
    this.expirationDate = suggestion.expiration_date;
    this.dateRangeText = formatDateLong(suggestion.starting_date)
      + ((suggestion.expiration_date) ? ` → ${formatDateLong(suggestion.expiration_date)}` : '');
    this.position = suggestion.position;
    this.positionText = suggestionPositions[suggestion.position] || suggestion.position;
    this.targets = suggestion.targets;
    this.hubs = suggestion.hubs;
    this.totalRegionsTitle = 'Все хабы';
    this.restaurants = suggestion.restaurants;
    this.labelsToOffer = suggestion.labels_to_offer;
    this.labelsToInclude = suggestion.labels_to_include;
    this.labelsToExclude = suggestion.labels_to_exclude;
    this.state = suggestion.status;
    this.isDeleted = suggestion.is_deleted;
    this.createdAt = suggestion.created_at;
    this.createdAtText = suggestion.created_at ? formatDateLong(suggestion.created_at) : '';
    this.updatedAt = suggestion.updated_at;
    this.isExpired = suggestion.expiration_date
      ? isOlderThanToday(suggestion.expiration_date) : false;
    this.cities = suggestion.cities;
  }

  get deserialized() {
    return {
      id: this.id,
      title: this.title,
      starting_date: this.startingDate ? this.startingDate : null,
      expiration_date: this.expirationDate ? this.expirationDate : null,
      position: this.position,
      targets: this.targets ? this.targets.map((target) => ({ id: target.pk || target.id })) : [],
      hubs: this.hubs ? this.hubs.map((hub) => ({ id: hub.id })) : [],
      restaurants: this.restaurants
        ? this.restaurants.map((restaurant) => ({ id: restaurant.pk || restaurant.id })) : [],
      labels_to_offer: this.labelsToOffer
        ? this.labelsToOffer.map((label) => ({ id: label.id })) : [],
      labels_to_include: this.labelsToInclude
        ? this.labelsToInclude.map((label) => ({ id: label.id })) : [],
      labels_to_exclude: this.labelsToExclude
        ? this.labelsToExclude.map((label) => ({ id: label.id })) : [],
      status: this.state,
      is_deleted: this.isDeleted,
      cities: this.cities.map((city) => ({ id: city.pk })),
    };
  }
}

export const serializeSuggestion = (suggestion) => new Suggestion(suggestion);

export const serializeSuggestionsList = (suggestions) => suggestions.map(serializeSuggestion);

export const deserializeSuggestion = (toSuggestion) => {
  const suggestion = new Suggestion();
  Object.assign(suggestion, toSuggestion);
  return suggestion.deserialized;
};

export const deserializeSuggestionsList = (suggestions) => suggestions.map(deserializeSuggestion);
