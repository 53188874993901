import { serializeDeliveryConditionList } from '@/serializers/deliveryConditionsSerializer';

export class DeliveryTariff {
  constructor(deliveryTariffData) {
    this.pk = deliveryTariffData ? deliveryTariffData.pk : null;
    this.title = deliveryTariffData ? deliveryTariffData.title : null;
    this.conditions = deliveryTariffData
      ? serializeDeliveryConditionList(deliveryTariffData.conditions) : null;
  }

  get deserialized() {
    return {
      title: this.title,
      conditions: this.conditions.map((c) => c.deserialized),
    };
  }
}

export const serializeDeliveryTariff = (tariff) => new DeliveryTariff(tariff);
export const serializeDeliveryTariffList = (tariffs) => tariffs.map(serializeDeliveryTariff);
