import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'main',
      component: () => import('./pages/Main.vue'),
      redirect: 'restaurants',
      children: [
        {
          path: '/delivery-areas',
          name: 'delivery',
          component: () => import('./pages/DeliveryAreas.vue'),
        },
        {
          path: '/categories',
          name: 'categories',
          component: () => import('./pages/Category.vue'),
        },
        {
          path: '/restaurants',
          name: 'restaurants',
          component: () => import('./pages/Restaurants.vue'),
        },
        {
          path: '/restaurants/edit/:pk',
          name: 'edit_restaurant',
          component: () => import('./pages/RestaurantEdit.vue'),
        },
        {
          path: '/restaurants/add',
          name: 'add_restaurant',
          component: () => import('./pages/RestaurantEdit.vue'),
        },
        {
          path: '/menu',
          name: 'menu',
          component: () => import('./pages/Menu.vue'),
        },
        {
          path: '/tariffs',
          name: 'tariffs',
          component: () => import('./pages/Tariffs.vue'),
        },
        {
          path: '/promotions',
          name: 'promotions',
          component: () => import('./pages/Promotions.vue'),
        },
        {
          path: '/banners',
          name: 'banners',
          component: () => import('./pages/Banners.vue'),
        },
        {
          path: '/top_6',
          name: 'top_6',
          component: () => import('./pages/TopRestaurants.vue'),
        },
        {
          path: '/image-performance',
          name: 'image-performance',
          component: () => import('./pages/ImagePerformance.vue'),
        },
        {
          path: '/moderation',
          name: 'moderation',
          component: () => import('./pages/Moderation.vue'),
        },
        {
          path: '/moderation-items/:pk',
          name: 'moderation-items',
          component: () => import('./pages/ModerationItems.vue'),
        },
        {
          path: '/cities',
          name: 'cities',
          component: () => import('./pages/Cities.vue'),
        },
        {
          path: '/hubs',
          name: 'hubs',
          component: () => import('./pages/Hubs.vue'),
        },
        {
          path: '/contracts',
          name: 'contracts',
          component: () => import('./pages/Contracts.vue'),
        },
        {
          path: '/contracts/add',
          name: 'contracts-add',
          component: () => import('./pages/ContractEdit.vue'),
        },
        {
          path: '/contracts/edit/:id',
          name: 'contracts-edit',
          component: () => import('./pages/ContractEdit.vue'),
        },
        {
          path: '/labels',
          name: 'labels',
          component: () => import('./pages/FoodLabels.vue'),
        },
        {
          path: '/stories',
          name: 'stories',
          component: () => import('./pages/Stories.vue'),
        },
        {
          path: '/upsell',
          name: 'upsell',
          component: () => import('./pages/Upsell.vue'),
        },
        {
          path: '/synonyms',
          name: 'synonyms',
          component: () => import('./pages/Synonyms.vue'),
        },
        {
          path: '/integration',
          name: 'integration',
          component: () => import('./pages/Integration.vue'),
        },
        {
          path: '/notifications',
          name: 'notifications',
          component: () => import('./pages/Notifications.vue'),
        },
        {
          path: '/collections',
          name: 'collections',
          component: () => import('./pages/Collections.vue'),
        },
        {
          path: '/income-partners',
          name: 'income-partners',
          component: () => import('./pages/IncomePartners.vue'),
        },
        {
          path: '/chips',
          name: 'chips',
          component: () => import('./pages/Chips.vue'),
        },
        {
          path: '/polygons',
          name: 'polygons',
          component: () => import('./modules/polygons/pages/Main.vue'),
        },
        {
          path: '/smart-restaurant',
          name: 'smart-restaurant',
          component: () => import('./pages/SmartRestaurant.vue'),
        },
      ],
    },
  ],
});

export default router;
