class RestaurantPhone {
  constructor(data = {}) {
    this.id = data ? data.id : null;
    this.title = data ? data.title : '';
    this.number = data ? data.number : '';
    this.isDefault = data ? data.is_default : false;
    this.restaurantId = data ? data.restaurant_id : null;
  }

  get deserialized() {
    return {
      id: this.id,
      title: this.title,
      number: this.number,
      is_default: this.isDefault,
      restaurant_id: this.restaurantId,
    };
  }
}

export const serializeRestaurantPhone = (phone) => new RestaurantPhone(phone);
export const serializeRestaurantPhonesList = (phones) => phones.map(serializeRestaurantPhone);

export const deserializeRestaurantPhone = (phone) => {
  const instance = new RestaurantPhone(phone);
  Object.assign(instance, phone);
  return instance.deserialized;
};
export const deserializeRestaurantPhonesList = (phones) => phones.map(deserializeRestaurantPhone);
