class ModificationChoice {
  constructor(data) {
    this.oid = data.oid;
    this.title = data.title;
    this.max_of_choices = parseInt(data.maxOfChoices, 10) ? parseInt(data.maxOfChoices, 10) : 0;
    this.min_of_choices = parseInt(data.minOfChoices, 10) ? parseInt(data.minOfChoices, 10) : 0;
    this.price = data.price ? data.price : 0;
  }
}
export const deserializeModificationChoice = (choice) => new ModificationChoice(choice);

export const deserializeModificationChoiceList = (chs) => chs.map(deserializeModificationChoice);
