import { deserializeCartEventConditionList } from './cartEventConditionSerializer';

export class CartEvent {
  constructor(data = {}) {
    this.foodId = data.food_id || null;
    this.title = data.title || null;
    this.state = data.state || null;
    this.position = data.position || 0;
    this.createdDt = data.created || null;
    this.updatedDt = data.modified || null;
    this.id = data.id || null;
    this.disclaimer = data.disclaimer || '';
    this.restaurant_id = data.restaurant_id || '';
    this.conditions = data.conditions || [];
    this.terms = data.terms || null;
  }

  get deserialized() {
    const payload = {
      title: this.title,
      disclaimer: this.disclaimer,
      food_id: this.foodId,
      state: this.state,
      position: this.position,
      restaurant_id: this.restaurant_id,
      terms: this.terms,
    };
    if (this.createdDt) {
      payload.created = this.createdDt;
    }
    if (this.updatedDt) {
      payload.modified = this.updatedDt;
    }
    if (this.conditions) {
      payload.conditions = deserializeCartEventConditionList(this.conditions);
    }
    return payload;
  }
}

export const serializeCartEvent = (val) => new CartEvent(val);

export const serializeCartEventList = (list) => list.map(serializeCartEvent);

export const deserializeCartEvent = (toCartEvent) => {
  const cartEvent = new CartEvent();
  Object.assign(cartEvent, toCartEvent);
  return cartEvent.deserialized;
};
