export class CollectionContent {
  constructor(content = {}) {
    this.id = content.id;
    this.presetId = content.preset_id;
    this.value = {};
    this.valueWithTitles = content.value_with_titles;
    this.int = content.value ? content.value.max_delivery_cost : 0;
  }

  get deserialized() {
    return {
      id: this.id,
      preset_id: this.presetId,
      value: this.value,
    };
  }
}

export const serializeCollectionContent = (content = {}) => new CollectionContent(content);

export const deserializeCollectionContent = (toContent = {}) => {
  const content = new CollectionContent();
  Object.assign(content, toContent);
  return content.deserialized;
};
