export class HubRestaurantShort {
  constructor(data = {}) {
    this.id = data.restaurant_id;
    this.title = data.title;
  }

  get deserialized() {
    return {
      id: this.id,
      title: this.title,
    };
  }
}

export const serializeHubRestaurantShort = (restaurant) => new HubRestaurantShort(restaurant);
export const serializeHubRestaurantsListShort = (restaurants) => restaurants
  .map(serializeHubRestaurantShort);
