export class DeliveryTime {
  constructor(deliveryTimeData) {
    this.pk = deliveryTimeData ? deliveryTimeData.pk : null;
    this.lowLimitMinutes = deliveryTimeData ? deliveryTimeData.low_limit_minutes : null;
    this.upperLimitMinutes = deliveryTimeData ? deliveryTimeData.upper_limit_minutes : null;
  }

  get title() {
    return `${this.lowLimitMinutes} - ${this.upperLimitMinutes}`;
  }
}

export const serializeDeliveryTime = (deliveryTime) => new DeliveryTime(deliveryTime);
// eslint-disable-next-line max-len
export const serializeDeliveryTimeList = (deliveryTimes) => deliveryTimes.map(serializeDeliveryTime);
