export class CollectionCondition {
  constructor(condition = {}) {
    this.key = condition.key;
    this.value = condition.value;
    this.valueWithTitles = condition.key === 'page' ? [] : condition.value_with_titles;
    this.selectedPage = condition.key === 'page' ? condition.value.pages[0] : condition.key;
  }

  get deserialized() {
    return {
      key: this.key,
      value: this.value,
    };
  }
}

export const serializeCollectionCondition = (condition) => new CollectionCondition(condition);

export const deserializeCollectionCondition = (toCondition) => {
  const condition = new CollectionCondition();
  Object.assign(condition, toCondition);
  return condition.deserialized;
};
