export const formatPrice = (val) => new Intl.NumberFormat('ru-KZ', {
  style: 'currency',
  currency: 'KZT',
  maximumSignificantDigits: 10,
})
  .format(val);

export const parseNumber = (value) => {
  const keyCode = (value.keyCode ? value.keyCode : value.button);
  if (keyCode < 48 || keyCode > 57) {
    value.preventDefault();
  }
};
