export const weekDays = {
  monday: 'Понедельник',
  tuesday: 'Вторник',
  wednesday: 'Среда',
  thursday: 'Четверг',
  friday: 'Пятница',
  saturday: 'Суббота',
  sunday: 'Воскресенье',
};
export const nextWeekDays = {
  monday: 'tuesday',
  tuesday: 'wednesday',
  wednesday: 'thursday',
  thursday: 'friday',
  friday: 'saturday',
  saturday: 'sunday',
  sunday: 'monday',
};
export class RestaurantScheduleItem {
  constructor(schedule) {
    this.startedWeekday = schedule ? schedule.started_week_day : null;
    this.startedAt = schedule ? schedule.started_at : null;
    this.endedWeekday = schedule ? schedule.ended_week_day : null;
    this.endedAt = schedule ? schedule.ended_at : null;
  }

  get deserialized() {
    return {
      started_week_day: this.startedWeekday,
      started_at: this.startedAt,
      ended_week_day: this.endedWeekday,
      ended_at: this.endedAt,
    };
  }
}

export const serializeRestaurantScheduleItem = (item = {}) => new RestaurantScheduleItem(item);
export const serializeRestaurantSchedule = (schedule = []) => schedule.map(
  serializeRestaurantScheduleItem,
);
