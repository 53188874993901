export class Category {
  constructor(category) {
    this.id = category ? category.pk : null;
    this.title = category ? category.title : null;
    this.titleKz = category ? category.title_kz : null;
    this.position = category ? category.position : null;
    this.activeIcon = category ? category.active_icon : null;
    this.inActiveIcon = category ? category.inactive_icon : null;
    this.imageForDigitalRestaurants = category ? category.image_for_digital_restaurants : null;
  }

  get deserialized() {
    return {
      title: this.title,
      title_kz: this.titleKz,
      position: this.position,
      active_icon: this.activeIcon,
      inactive_icon: this.inActiveIcon,
      image_for_digital_restaurants: this.imageForDigitalRestaurants,
    };
  }
}

export const serializeCategory = (category = {}) => new Category(category);

export const serializeCategoryList = (categories = []) => categories.map(serializeCategory);
