import { serializeModerationItem } from './ModerationItemSerializer';

class ModerationDocument {
  constructor(doc) {
    this.documentId = doc.moderation_id || '';
    this.actionType = doc.action_type || '';
    this.foodType = doc.category ? serializeModerationItem(doc.category) : null;
    this.food = doc.food ? serializeModerationItem(doc.food) : null;
  }
}

export const serializeModerationDocument = (document) => new ModerationDocument(document);

export const serializeModerationDocumentsList = (documents) => documents
  .map(serializeModerationDocument);
