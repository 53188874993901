import { formatPrice } from '../helpers/price';
import { formatDate, recentlyUpdated } from '../helpers/date';
import { serializeFoodOrOptionCategoryList, deserializeFoodOrOptionCategoryList } from './foodOrOptionCategorySerializer';
import { serializeFoodModifierList, deserializeFoodModifierList } from './foodModifierSerializer';
import { serializeFoodLabelList, deserializeFoodLabelList } from './foodLabelsSerializer';

export class FoodOrOption {
  constructor(data = {}) {
    this.id = data.id || undefined;
    this.title = data.title || undefined;
    this.price = data.price || 0;
    this.formattedPrice = data && data.price ? formatPrice(data.price) : 0;
    this.titleAndPrice = `${data.title} - ${data.price || 0}₸`;
    this.description = data.description || undefined;
    this.categories = data
    && data.categories ? serializeFoodOrOptionCategoryList(data.categories) : [];
    this.state = data.state || undefined;
    this.image = data.image || undefined;
    this.isFood = data.is_food || undefined;
    this.isOption = data.is_option || undefined;
    this.isAlcohol = data.is_alcohol || undefined;
    this.createdDt = data && data.created_dt ? formatDate(data.created_dt) : undefined;
    this.updatedDt = data && data.updated_dt ? formatDate(data.updated_dt) : undefined;
    this.atModeration = data.at_moderation || undefined;
    this.modifiers = data && data.modifiers ? serializeFoodModifierList(data.modifiers) : [];
    this.isRecentlyUpdated = data ? recentlyUpdated(data) : false;
    this.position = data.position || undefined;
    this.labels = data && data.labels ? serializeFoodLabelList(data.labels) : undefined;
    this.score = data.score || 0;
    this.isCommissionFree = data.is_commission_free;
    this.isVisible = data.is_visible;
  }

  get deserialized() {
    return {
      id: this.id,
      title: this.title,
      price: parseInt(this.price, 10) || 0,
      description: this.description,
      categories: deserializeFoodOrOptionCategoryList(this.categories),
      state: this.state,
      image: this.image,
      is_food: this.isFood,
      is_option: this.isOption,
      is_alcohol: this.isAlcohol,
      modifiers: this.modifiers ? deserializeFoodModifierList(this.modifiers) : [],
      position: this.position,
      labels: this.labels ? deserializeFoodLabelList(this.labels) : [],
      is_commission_free: this.isCommissionFree,
      is_visible: this.isVisible,
    };
  }
}

export const serializeFoodOrOption = (val) => new FoodOrOption(val);

export const serializeFoodOrOptionList = (list) => list.map(serializeFoodOrOption);

export const deserializeFoodOrOption = (toFoodOrOption) => {
  const foodOrOption = new FoodOrOption();
  Object.assign(foodOrOption, toFoodOrOption);
  return foodOrOption.deserialized;
};

export const deserializeFoodOrOptionList = (list) => list.map(deserializeFoodOrOption);
