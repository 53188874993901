class IntegrationDataSerializer {
  constructor(data = {}) {
    this.name = data.name || data.iiko_organization_title || '';
    this.id = data.id || data.iiko_organization_id || 0;
    this.nameAndId = `${this.name} (${this.id})`;
    this.srOrganizationId = data.sr_organization_id || null;
  }
}

const serializeIntegrationData = (data = {}) => new IntegrationDataSerializer(data);
export const serializeIntegrationDataList = (data = []) => data
  .map(serializeIntegrationData);
