import { h3ToGeo, pointDist } from 'h3-js';

export const getRadiusBySetOfH3Indexes = (h3Indexes) => {
  const coordinates = h3Indexes.map((h3) => h3ToGeo(h3));
  const centroid = coordinates.reduce((acc, coord) => {
    acc[0] += coord[0];
    acc[1] += coord[1];
    return acc;
  }, [0, 0]).map((coord) => coord / coordinates.length);
  const distances = coordinates.map((coord) => pointDist(centroid, coord, 'm'));

  const innerRadius = Math.min(...distances); // Minimum distance (closest hexagon)
  const outerRadius = Math.max(...distances); // Maximum distance (farthest hexagon)

  return {
    inner: Math.round(innerRadius / 100) * 100,
    outer: Math.round(outerRadius / 100) * 100,
  };
};
export const getMaxDistanceFromPoint = (h3Indexes, centerPoint) => {
  const coordinates = h3Indexes.map((h3) => h3ToGeo(h3)); // Преобразуем H3-индексы в геокоординаты

  const distances = coordinates.map((coord) => pointDist(centerPoint, coord, 'm')); // Вычисляем расстояния от centerPoint до каждой точки

  const maxDistance = Math.max(...distances); // Находим максимальное расстояние
  return (Math.round(maxDistance / 100) * 100) / 1000; // Округляем до ближайшей сотни
};

export const deliveryAreaColors = [
  '2, 136, 209',
  '255, 235, 59',
  '244, 67, 54',
  '76, 175, 80',
  '255, 152, 0',
  '0, 188, 212',
  '94, 53, 177',
  '233, 30, 99',
  '255, 87, 34',
  '105, 240, 174',
  '175, 180, 43',
  '41, 98, 255',
  '213, 0, 249',
  '136, 14, 79',
  '174, 234, 0',
  '62, 39, 35',
  '0, 230, 118',
  '191, 54, 12',
  '1, 87, 155',
  '0, 137, 123',
];

export const zoneMinSums = {
  2000: 2000,
  3000: 3000,
  4000: 4000,
  5000: 5000,
  6000: 6000,
  7000: 7000,
  8000: 8000,
  9000: 9000,
};

export const zoneSteps = [
  { title: '100 м', distance: 100 },
  { title: '200 м', distance: 200 },
  { title: '300 м', distance: 300 },
  { title: '1 км', distance: 1000 },
  { title: '2 км', distance: 2000 },
  { title: '3 км', distance: 3000 },
  { title: '4 км', distance: 4000 },
];

export const courierCostByCity = {
  Актау: { supply: 341, km: 153 },
  Актобе: { supply: 571, km: 171 },
  Алматы: { supply: 403, km: 151 },
  Астана: { supply: 700, km: 200 },
  Атырау: { supply: 492, km: 148 },
  Караганда: { supply: 490, km: 180 },
  Каскелен: { supply: 403, km: 151 },
  Кокшетау: { supply: 530, km: 132 },
  Костанай: { supply: 288, km: 158 },
  Косшы: { supply: 700, km: 200 },
  'Кульджинский тракт': { supply: 403, km: 151 },
  Кунаев: { supply: 530, km: 132 },
  Кызылорда: { supply: 350, km: 100 },
  Ленгер: { supply: 530, km: 132 },
  Павлодар: { supply: 558, km: 111.6 },
  Петропавловск: { supply: 481, km: 192.5 },
  'село Отеген батыр': { supply: 403, km: 151 },
  Семей: { supply: 530, km: 132 },
  Талгар: { supply: 530, km: 132 },
  Талдыкорган: { supply: 700, km: 70 },
  Тараз: { supply: 530, km: 132 },
  Темиртау: { supply: 530, km: 132 },
  Уральск: { supply: 530, km: 132 },
  'Усть-Каменогорск': { supply: 333.8, km: 133.5 },
  Шымкент: { supply: 310, km: 217 },
  Экибастуз: { supply: 530, km: 132 },
};
