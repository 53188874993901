export class ImageLink {
  constructor(image) {
    this.url = image.url;
    this.fileUrl = image.file_url;
    this.formData = Object.entries(image.form_data).reduce((form, [key, value]) => {
      form.append(key, value);
      return form;
    }, new FormData());
  }

  setFile(file) {
    this.formData.append('file', file);
  }

  get imageURL() {
    return this.fileUrl;
  }
}

export const serializeImageLink = (imageLink) => new ImageLink(imageLink);
