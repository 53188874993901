class RestaurantContract {
  constructor(contract) {
    this.restaurantId = contract.restaurant_id || '';
    this.isDefault = contract.is_default || false;
    this.contractId = contract.contract_id || 0;
  }

  get deserialized() {
    return {
      restaurant_id: this.restaurantId,
      is_default: this.isDefault,
      contract_id: this.contractId,
    };
  }
}

export const serializeRestaurantContract = (contract) => new RestaurantContract(contract);
export const serializeRestaurantContractsList = (contracts) => contracts
  .map(serializeRestaurantContract);

export const deserializeRestaurantContract = (contract) => {
  const instance = new RestaurantContract(contract);
  Object.assign(instance, contract);
  return instance.deserialized;
};
export const deserializeRestaurantContractsList = (contracts) => contracts
  .map(deserializeRestaurantContract);
