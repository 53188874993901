import { serializeRestaurant } from '@/serializers/restaurantSerializer';
import moment from 'moment';

export const promotionTypes = {
  informational: 'Информационная',
  discount: 'Скидка',
};

export const discountTypes = {
  percent: '%',
  fixed: '₸',
};

export const serializePromotionType = (promotionType = {}) => ({
  discountType: promotionType.discount_type || '',
  foodIds: promotionType.food_ids || [],
  amount: promotionType.amount || null,
});

export const deserializePromotionType = (promotionType = {}) => ({
  discount_type: promotionType.discountType,
  food_ids: promotionType.foodIds,
  amount: promotionType.amount,
});

const addTime = (date, type) => {
  const dateFormats = {
    start: new Date(date).setHours(0, 0, 0, 0),
    end: new Date(date).setHours(23, 59, 59, 59),
  };
  return new Date(dateFormats[type]).toISOString();
};

const removeTime = (date) => new Date(date).toISOString().split('T')[0];

const today = new Date().toISOString().split('T')[0];

export class Promotion {
  constructor(promotion = {}) {
    this.pk = promotion.pk || '';
    this.header = promotion.header || '';
    this.headerKz = promotion.header_kz || '';
    this.headerShort = promotion.header_short || '';
    this.headerShortKz = promotion.header_short_kz || '';
    this.image = promotion.image || '';
    this.iconName = promotion.icon_name || '';
    this.restaurant = promotion.restaurant ? serializeRestaurant(promotion.restaurant) : null;
    this.restaurantId = promotion.restaurant_id || '';
    this.typeName = promotion.type_name || '';
    this.promotionType = serializePromotionType(promotion.promotion_type || {});
    this.startedAt = promotion.started_at ? removeTime(promotion.started_at) : today;
    this.endedAt = promotion.ended_at ? removeTime(promotion.ended_at) : today;
    this.description = promotion.description || '';
    this.descriptionKz = promotion.description_kz || '';
    this.platforms = promotion.platforms || [];
    this.position = promotion.position || 0;
  }

  get typeDescription() {
    return promotionTypes[this.typeName];
  }

  get isActive() {
    const currentTime = moment(new Date(), 'YYYY-MM-DD HH:mm').utc().toISOString(true);
    return this.startedAt < currentTime && this.endedAt > currentTime;
  }

  get deserialized() {
    return {
      header: this.header,
      header_kz: this.headerKz,
      header_short: this.headerShort,
      header_short_kz: this.headerShortKz,
      image: this.image,
      icon_name: this.iconName,
      restaurant_id: this.restaurant.pk,
      type_name: this.typeName,
      promotion_type: this.typeName === 'discount' ? deserializePromotionType(this.promotionType) : {},
      started_at: addTime(this.startedAt, 'start'),
      ended_at: addTime(this.endedAt, 'end'),
      description: this.description,
      description_kz: this.descriptionKz,
      platforms: this.platforms,
      position: this.position,
    };
  }
}

export const serializePromotion = (promotion) => new Promotion(promotion);

export const serializePromotionList = (promotions) => promotions.map(serializePromotion);
