/* eslint-disable no-underscore-dangle */
import moment from 'moment';
import { serializeBannerAction, deserializeBannerAction } from './bannerActionSerializer';

class Banner {
  constructor(data = {}) {
    this.id = data._id;
    this.title = data.title;
    this.imageUrl = data.image_url || '';
    this.type = data.type || 'main_slider';
    this.action = serializeBannerAction(data.action);
    this.cities = data.cities || [];
    this.dateStart = data.date_start || moment.now();
    this.dateEnd = data.date_end || moment.now();
    this.isActive = data.is_active || true;
    this.targets = data.targets || [];
    this.languages = data.languages || [];
    this.position = data.position || 0;
  }

  get deserialized() {
    return {
      _id: this.id,
      title: this.title,
      image_url: this.imageUrl,
      type: this.type,
      action: deserializeBannerAction(this.action),
      cities: this.cities,
      date_start: this.dateStart,
      date_end: this.dateEnd,
      is_active: this.isActive,
      targets: this.targets,
      languages: this.languages,
      position: this.position || 0,
    };
  }
}

export const serializeBanner = (banner) => new Banner(banner);

export const serializeBannersList = (banners) => banners.map(serializeBanner);

export const deserializeBanner = (sourceBanner) => {
  const targetBanner = new Banner();
  Object.assign(targetBanner, sourceBanner);
  return targetBanner.deserialized;
};
