import moment from 'moment';
import {
  deserializeContactRolesList,
  serializeContactRole,
  serializeContactRolesList,
} from './contractContactRoleSerializer';

const getPrepaymentsDetails = (isHalfMonth, isWeekly) => {
  if (isHalfMonth) {
    return 'Данный контракт работает на внеочередном авансе';
  }
  if (isWeekly) {
    return 'Данный контракт работает на еженедельном авансе';
  }
  return 'Данный контракт работает без авансов';
};

export class Contract {
  constructor(data = {}) {
    this.address = data.address || '';
    this.canApprove = data.can_approve || false;
    this.canDelete = data.can_delete || false;
    this.approved = data.approved || false;
    this.bankAccount = data.bank_account || '';
    this.bankName = data.bank_name || '';
    this.bic = data.bic || '';
    this.bin = data.bin || '';
    this.city = data.city || '';
    this.createdDt = moment(data.created_dt).format('YYYY-MM-DD');
    this.contactRoles = data.contact_roles
      ? serializeContactRolesList(data.contact_roles)
      : [serializeContactRole()];
    this.date = data.date || '';
    this.commissionStartDate = data.commission_start_date || '';
    this.description = data.description || '';
    this.email = data.email || '';
    this.id = data.id || 0;
    this.isDefault = data.is_default || false;
    this.isCurrent = data.is_current || false;
    this.isDeleted = data.is_deleted || false;
    this.legalAddress = data.legal_address || '';
    this.manager = data.manager || '';
    this.number = data.number || '';
    this.organizationName = data.organization_name || '';
    this.restaurantContractId = data.restaurant_contract_id || undefined;
    this.title = data.title || '';
    this.percent = data.commission_percent;
    this.terminatedAt = data.terminated_at || '';
    this.isTerminated = data.is_terminated || false;
    this.prepaymentsDetails = getPrepaymentsDetails(
      data.half_month_prepayment, data.weekly_prepayment,
    );
  }

  get deserialized() {
    return {
      address: this.address || '',
      approved: this.approved || false,
      bank_account: this.bankAccount || '',
      bank_name: this.bankName || '',
      bic: this.bic || '',
      bin: this.bin || '',
      city: this.city || '',
      created_dt: this.createdDt,
      contact_roles: deserializeContactRolesList(this.contactRoles),
      date: this.date || '',
      description: this.description || '',
      email: this.email || '',
      id: this.id || undefined,
      is_default: this.isDefault || false,
      is_current: this.isCurrent || false,
      is_deleted: this.isDeleted || false,
      legal_address: this.legalAddress || '',
      manager: this.manager || '',
      number: this.number || '',
      organization_name: this.organizationName || '',
      restaurant_contract_id: this.restaurantContractId || undefined,
      title: this.title || '',
      commission_percent: this.percent,
      terminated_at: this.terminatedAt || null,
    };
  }
}

export const serializeContract = (contract) => new Contract(contract);
export const serializeContractsList = (contracts) => contracts.map(serializeContract);
