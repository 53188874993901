import { dateFromBackend } from '@/helpers/date';

class Contact {
  constructor(contact) {
    this.name = contact.name || '';
    this.phone = contact.phone || '';
    this.email = contact.email || '';
    this.role = contact.role || '';
    this.id = contact.id || 0;
    this.contractId = contact.contract_id || 0;
    this.isDeleted = contact.is_deleted || false;
    this.createdDt = contact.created_dt
      ? dateFromBackend(contact.created_dt, 'YYYY-MM-DD')
      : '';
  }

  get deserialized() {
    return {
      name: this.name,
      phone: this.phone,
      email: this.email,
      role: this.role,
      id: this.id,
      contract_id: this.contractId,
      is_deleted: this.isDeleted,
      created_dt: this.createdDt,
    };
  }
}

export const serializeContact = (contact) => new Contact(contact);
export const serializeContactsList = (contacts) => contacts.map(serializeContact);

export const deserializeContact = (contact) => {
  const instance = new Contact(contact);
  Object.assign(instance, contact);
  return instance.deserialized;
};
export const deserializeContactsList = (contacts) => contacts.map(deserializeContact);
