import { deserializeIngredientList } from './ingredientDeserializer';
import { deserializeMeasure } from './measureDeserializer';
import { deserializeAdditionList } from './additionDeserializer';
import { deserializeFoodModificationList } from './foodModificationDeserializer';

class Food {
  constructor(data) {
    this.oid = data.oid;
    this.title = data.title;
    this.position = data.position;
    this.selling_text = data.sellingText;
    this.state = data.state;
    this.price = typeof data.price === 'string' || typeof data.price === 'number' ? parseInt(data.price, 10) : undefined;
    this.logo = data.logo;
    this.cooking_method = data.cookingMethod;
    this.measure = data.measure ? deserializeMeasure(data.measure) : undefined;
    this.ingredients = data.ingredients ? deserializeIngredientList(data.ingredients) : [];
    this.additions = data.additions ? deserializeAdditionList(data.additions) : [];
    this.modifications = data.modifications
      ? deserializeFoodModificationList(data.modifications)
      : [];
  }
}
export const deserializeFood = (food) => new Food(food);

export const deserializeFoodList = (foods) => foods.map(deserializeFood);
