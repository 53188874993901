import moment from 'moment';

export const dateFromBackend = (date, format) => moment(date).format(format);

const options = {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
};

const monthsInflected = ['января', 'февраля', 'марта', 'апреля', 'мая', 'июня', 'июля', 'августа', 'сентября', 'октября', 'ноября', 'декабря'];

const getMinutes = (date) => {
  const minutes = new Date(date).getMinutes();
  return minutes < 10 ? `0${minutes}` : minutes;
};

export const formatDate = (date) => (date ? `${new Date(date).toLocaleString('ru', options)}
 (${new Date(date).getHours()}:${getMinutes(date)})` : null);

export const recentlyUpdated = (item) => {
  const lastUpdatedDate = item.updated_dt || item.created_dt;
  return (Math.abs(new Date() - new Date(lastUpdatedDate)) / 1000 / 60) <= 15;
};

export const getLabelFormatDate = (date) => moment(date).format('DD.MM.YYYY - HH:mm');

export const capitalizeFirstLetter = (string) => string.charAt(0).toUpperCase() + string.slice(1);

export const twoDigitFormatting = (number) => ((number < 10) ? (`0${number}`) : number);

export const formatDateWithTime = (rawDate) => {
  const date = new Date(rawDate);
  const day = date.toLocaleDateString('ru', { day: 'numeric' });
  const month = monthsInflected[date.getMonth()];
  const year = date.getFullYear();
  const hours = twoDigitFormatting(date.getHours());
  const minutes = twoDigitFormatting(date.getMinutes());
  return `${day} ${month} ${year} • ${hours}:${minutes}`;
};

export const formatDateLong = (rawDate) => {
  const date = new Date(rawDate);
  const day = date.toLocaleDateString('ru-KZ', { day: 'numeric' });
  const month = capitalizeFirstLetter(date.toLocaleDateString('ru-KZ', { month: 'long' }));
  const year = date.getFullYear();
  return `${day} ${month}, ${year}`;
};

export const formatDateMonthAndYear = (rawDate) => {
  const date = new Date(rawDate);
  const month = capitalizeFirstLetter(date.toLocaleDateString('ru-KZ', { month: 'long' }));
  const year = date.getFullYear();
  return `${month} ${year}`;
};

export const isOlderThanToday = (date) => new Date(date) < new Date();
