class HotTariff {
  constructor(data) {
    this.title = data.title;
    this.courierFee = data.courier_fee;
    this.createdAt = data.created_at;
    this.updatedAt = data.updated_at;
    this.deliveryIncreaseRate = data.delivery_increase_rate;
    this.id = data.id;
    this.isActive = data.is_active;
    this.minMoa = data.min_moa;
    this.maxMoa = data.max_moa;
    this.minDelivery = data.min_delivery;
    this.maxDelivery = data.max_delivery;
    this.moaIncreaseRate = data.moa_increase_rate;
  }
}

export const serializeHotTariff = (tariff) => new HotTariff(tariff);

export const serializeHotTariffsList = (arrayOfTariffs) => arrayOfTariffs.map(serializeHotTariff);
