export class Addition {
  constructor(addition) {
    this.oid = addition ? addition.oid : null;
    this.title = addition ? addition.title : null;
    this.price = addition ? addition.price : null;
  }

  get deserialized() {
    return {
      oid: this.oid,
      title: this.title,
      price: this.price,
    };
  }
}

export const serializeAddition = (addition) => new Addition(addition);

export const serializeAdditionList = (additions) => additions.map(serializeAddition);
