export class PartnerRestaurants {
  constructor(data = {}) {
    this.hermesId = data.hermes_restaurant_id || null;
    this.hermesName = data.hermes_restaurant_name || '';
    this.partnerId = data.partner_restaurant_id || '';
    this.comment = data.comment || '';
    this.id = data.id || null;
    this.total = data.total_count || null;
  }

  get deserialized() {
    return {
      hermes_restaurant_id: this.hermesId,
      hermes_restaurant_name: this.hermesName,
      partner_restaurant_id: this.partnerId,
      comment: this.comment,
    };
  }
}

export const serializePartnerRestaurants = (val) => new PartnerRestaurants(val);

export const serializePartnerRestaurantsList = (list) => list.map(serializePartnerRestaurants);

export const deserializePartnerRestaurants = (toPartners) => {
  const partners = new PartnerRestaurants();
  Object.assign(partners, toPartners);
  return partners.deserialized;
};
