import { getLabelFormatDate } from '../helpers/date';

export class FoodLabels {
  constructor(data) {
    this.id = data ? data.id : null;
    this.title = data ? data.title : null;
    this.keywords = data ? data.keywords : null;
    this.keywordsAnti = data ? data.anti_keywords : null;
    this.createdDt = data ? getLabelFormatDate(data.created_at) : null;
    this.updatedDt = data ? getLabelFormatDate(data.updated_at) : null;
    this.coeffRest = data ? data.rest_coeff : '';
    this.coeffFood = data ? data.food_coeff : '';
    this.lastSyncDate = data && data.last_sync_date
      ? getLabelFormatDate(data.last_sync_date) : null;
    this.loading = data
      ? data.sync_status === 'waiting' || data.sync_status === 'in progress' : false;
  }

  get deserialized() {
    return {
      title: this.title,
      keywords: this.keywords,
      anti_keywords: this.keywordsAnti,
      id: this.id,
    };
  }
}

export const serializeFoodLabel = (val) => new FoodLabels(val);

export const serializeFoodLabelList = (list) => list.map(serializeFoodLabel);

export const deserializeFoodLabel = (toFoodLabel) => {
  const foodLabel = new FoodLabels();
  Object.assign(foodLabel, toFoodLabel);
  return foodLabel.deserialized;
};

export const deserializeFoodLabelList = (list) => list.map(deserializeFoodLabel);
