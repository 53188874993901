import { languageTitles } from '@/helpers/table';
import { formatDateLong, isOlderThanToday } from '@/helpers/date';

export class Story {
  constructor(story = {}) {
    this.id = story.id;
    this.title = story.name_story;
    this.coverTitle = story.title;
    this.created = story.created;
    this.createdText = story.created ? formatDateLong(story.created) : '';
    this.dateStart = story.date_start;
    this.dateEnd = story.date_end;
    this.dateRangeText = formatDateLong(story.date_start)
      + ((story.date_end) ? ` → ${formatDateLong(story.date_end)}` : '');
    this.cities = story.cities || [];
    this.hubs = story.hubs || [];
    this.restaurants = story.restaurants || [];
    this.targets = story.targets || [];
    this.state = story.state;
    this.position = story.position || 0;
    this.cover = story.cover;
    this.isValidCover = !!(story && story.cover);
    this.dateModification = story.date_modification;
    this.slideCount = story.slide_count;
    this.isExpired = story.date_end ? isOlderThanToday(story.date_end) : false;
    this.languages = story.languages
      ? story.languages.map((language) => ({ title: languageTitles[language], code: language }))
      : [];
    this.isFullScreen = story.is_full_screen || false;
  }

  get deserialized() {
    return {
      id: this.id,
      name_story: this.title,
      title: this.coverTitle,
      date_start: this.dateStart,
      date_end: this.dateEnd,
      cities: this.cities ? this.cities.map((city) => city.pk || city.id) : [],
      hubs: this.hubs ? this.hubs.map((hub) => hub.id) : [],
      restaurants: this.restaurants ? this.restaurants.map((restaurant) => restaurant.id) : [],
      targets: this.targets ? this.targets.map((target) => target.value || target) : [],
      state: this.state,
      position: this.position || 0,
      cover: this.cover,
      slide_count: this.slideCount,
      languages: this.languages.map((lang) => lang.code),
      is_full_screen: this.isFullScreen,
    };
  }
}

export const serializeStory = (story) => new Story(story);

export const serializeStoriesList = (stories) => stories.map(serializeStory);

export const deserializeStory = (toStory) => {
  const story = new Story();
  Object.assign(story, toStory);
  return story.deserialized;
};

export const deserializeStoriesList = (stories) => stories.map(deserializeStory);
