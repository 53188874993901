import { serializeRestaurant } from '@/serializers/restaurantSerializer';
import { serializeTopCategory } from '@/serializers/topRestaurantCategorySerializer';

export class TopRestaurant {
  constructor(data) {
    this.id = data ? data.id : null;
    this.categoryId = data ? data.category_id : undefined;
    this.category = data ? serializeTopCategory(data.category) : null;
    this.restaurant = data ? serializeRestaurant(data.restaurant) : null;
    this.startedAt = data ? data.started_at : null;
    this.endedAt = data ? data.ended_at : null;
  }

  get deserialized() {
    return {
      id: this.id,
      category_id: this.categoryId,
      started_at: this.startedAt,
      ended_at: this.endedAt,
    };
  }
}

export const serializeTopRestaurant = (top) => new TopRestaurant(top);
export const serializeTopRestaurantList = (tops) => tops.map(serializeTopRestaurant);
