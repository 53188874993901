import {
  serializeModificationChoiceList,
  deserializeModificationChoiceList,
} from './foodModificationChoiceSerializer';

export class FoodModification {
  constructor(data) {
    this.oid = data ? data.oid : undefined;
    this.title = data ? data.title : undefined;
    this.maxOfChoices = data ? data.max_of_choices : undefined;
    this.minOfChoices = data ? data.min_of_choices : undefined;
    this.modificationChoices = data
      ? serializeModificationChoiceList(data.modification_choices) : [];
    this.externalServiceId = data ? data.external_service_id : undefined;
  }

  get deserialized() {
    return {
      oid: this.oid,
      title: this.title,
      max_of_choices: this.maxOfChoices ? parseInt(this.maxOfChoices, 10) : 0,
      min_of_choices: this.minOfChoices ? parseInt(this.minOfChoices, 10) : 0,
      modification_choices: this.modificationChoices
        ? deserializeModificationChoiceList(this.modificationChoices)
        : [],
      external_service_id: this.externalServiceId || undefined,
    };
  }
}

export const serializeFoodModification = (modification) => new FoodModification(modification);

export const serializeFoodModificationList = (mods) => mods.map(serializeFoodModification);

export const assignToNewFoodModification = (modification) => {
  const newModification = new FoodModification();
  Object.assign(newModification, modification);
  return newModification;
};

export const assignToNewFoodModificationsList = (mods) => mods.map(assignToNewFoodModification);
