export class FoodModifierOption {
  constructor(data) {
    this.id = data ? data.food_id : undefined;
    this.maxChoices = data ? data.max_choices : undefined;
    this.minChoices = data ? data.min_choices : undefined;
    this.price = data ? parseInt(data.price, 10) : 0;
    this.state = data ? data.state : undefined;
    this.title = data ? data.title : undefined;
    this.isVisible = data ? data.is_visible : undefined;
  }

  get deserialized() {
    return {
      food_id: this.id,
      max_choices: this.maxChoices || 1,
      min_choices: this.minChoices || 0,
      price: parseInt(this.price, 10) || 0,
      state: this.state,
      title: this.title,
    };
  }
}

export const serializeFoodModifierOption = (option) => new FoodModifierOption(option);

export const serializeFoodModifierOptionList = (list) => list.map(serializeFoodModifierOption);

export const deserializeFoodModifierOption = (toFoodModifierOption) => {
  const foodModifierOption = new FoodModifierOption();
  Object.assign(foodModifierOption, toFoodModifierOption);
  return foodModifierOption.deserialized;
};

export const deserializeFoodModifierOptionList = (list) => list.map(deserializeFoodModifierOption);
