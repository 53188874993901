import { deserializeFoodList } from './foodDeserializer';

class FoodType {
  constructor(data) {
    this.oid = data.oid;
    this.title = data.title;
    this.foods = data.foods ? deserializeFoodList(data.foods) : [];
    this.position = data.position;
  }
}
export const deserializeFoodType = (foodType) => new FoodType(foodType);

export const deserializeFoodTypeList = (foodTypes) => foodTypes.map(deserializeFoodType);
