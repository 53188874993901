class CollectionPresetParam {
  constructor(param = {}) {
    this.key = param.key;
    this.keyType = param.key_type;
    this.title = param.title;
    this.isRequired = param.is_required;
    this.description = param.description;
  }
}

export const serializeCollectionPresetParam = (param) => new CollectionPresetParam(param);

export const serializeCollectionPresetParamsList = (params) => params
  .map(serializeCollectionPresetParam);
