const formattedPhoneNumber = (phoneNumber) => phoneNumber.replace(/(\+7)(\d{3})(\d{3})(\d{4})/, '$1 $2 $3 $4');

class IncomePartnerSerialized {
  constructor(data = {}) {
    this.id = data.id;
    this.fullName = data.full_name;
    this.cityId = data.city_id;
    this.dtCreated = data.dt_created ? new Date(data.dt_created).toISOString().split('T')[0] : '';
    this.dtUpdated = data.dt_updated ? new Date(data.dt_updated).toISOString().split('T')[0] : '';
    this.email = data.email;
    this.manager = data.manager;
    this.phoneNumber = data.phone_number || '';
    this.restaurantName = data.restaurant_name;
    this.state = data.state;
    this.partnerDataCombined = `
      Название ресторана: ${this.restaurantName}<br>
      ФИО: ${this.fullName}<br>
      Email: ${this.email}<br>
      Номер телефона: ${this.phoneNumber.length ? formattedPhoneNumber(this.phoneNumber) : ''}<br>
    `;
  }

  get deserialized() {
    return {
      full_name: this.fullName,
      city_id: this.cityId,
      email: this.email,
      manager: this.manager,
      phone_number: this.phoneNumber,
      restaurant_name: this.restaurantName,
      state: this.state,
    };
  }
}

export const serializeIncomePartner = (value) => new IncomePartnerSerialized(value);

export const serializeIncomePartnersList = (list) => list.map(serializeIncomePartner);

export const deserializeIncomePartner = (value) => {
  const incomePartner = new IncomePartnerSerialized();
  Object.assign(incomePartner, value);
  return incomePartner.deserialized;
};
